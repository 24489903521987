<script lang="ts" setup></script>
<template>
  <div>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <NuxtLayout>
      <Suspense>
        <NuxtPage />
      </Suspense>
    </NuxtLayout>
  </div>
</template>
